import {Link} from 'gatsby'
import React, { useState } from 'react'
import TutorialPreview from './tutorial-preview'

import SimpleSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from './tutorial.module.css'

function TutorialPreviewGrid (props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    speed: 750,
    slidesToShow: 4.5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <div className={styles.slickWrap}>
        <div className={styles.slickSlider} id="tutorialSlider">
          <SimpleSlider {...settings} className={styles.posts} >
            {props.nodes &&
              props.nodes.map(node => (
                <div key={node.id} className={styles.post}>
                  <TutorialPreview {...node} />
                </div>
              ))}
          </SimpleSlider>
        </div>
      </div>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref + '/'}>Browse more</Link>
        </div>
      )}
    </div>
  )
}

TutorialPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default TutorialPreviewGrid
